import {
  useRef,
  useEffect,
  MutableRefObject,
  createRef,
  RefObject,
  useState,
} from "react"
import { useDebouncedCallback } from "use-debounce"

function useLastOnRow<T extends Element>(
  length: number,
  className: string
): [(MutableRefObject<RefObject<T>[]>), (string | null)[]] {
  const refs = useRef<RefObject<T>[]>([])
  const cns = []

  while (refs.current.length < length) {
    refs.current.push(createRef<T>())
    cns.push(null)
  }

  const [classNames, setClassNames] = useState<(string | null)[]>(cns)
  const [debounced] = useDebouncedCallback(() => {
    setClassNames(refs.current.map((_, i) => getClassName(i)))
  }, 120)

  useEffect(() => {
    window.addEventListener("resize", debounced)
    debounced()
  }, [])

  const getClassName = (i: number) => {
    const currEl = refs.current[i]
    const nextEl = refs.current[i + 1]

    if (!(currEl && currEl.current)) {
      return null
    }

    if (!(nextEl && nextEl.current)) {
      return className
    }

    return currEl.current.getBoundingClientRect().left >
      nextEl.current.getBoundingClientRect().left
      ? className
      : null
  }

  return [refs, classNames]
}

export default useLastOnRow
