import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function Logo() {
  const logo = useStaticQuery(graphql`
    query Logo {
      file(name: { regex: "/logo/" }) {
        publicURL
      }
    }
  `)

  return <img src={logo.file.publicURL} alt="Yalla Rinkeby" />
}
