import React, { FunctionComponent } from "react"
import styles from "./instagram-link.module.css"
import { useStaticQuery, graphql } from "gatsby"

const InstagramLink: FunctionComponent = () => {
  const { contentfulHeaderFooter: data } = useStaticQuery(graphql`
    query InstagramLink {
      contentfulHeaderFooter {
        instagramUrl
        instagramLabel
      }
    }
  `)

  return (
    <a className={styles.link} href={data.instagramUrl}>
      {data.instagramLabel}
    </a>
  )
}

export default InstagramLink
