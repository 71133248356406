import React, { FunctionComponent, ReactNode } from "react"

import styles from "./layout.module.css"
import Footer from "./footer"
import Menu, { MenuToggleColor } from "./menu"

const Layout: FunctionComponent<{
  toggleColor?: MenuToggleColor
  children: ReactNode
}> = ({ children, toggleColor = "pink" }) => {
  return (
    <>
      <div className={styles.wrap}>
        <Menu toggleColor={toggleColor} />
        <main className={styles.main}>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
