import rehypeReact from "rehype-react"
import { createElement, Fragment } from "react"

const renderAst = (ast?: object, components = {}) =>
  ast ? new rehypeReact({
    createElement,
    Fragment: Fragment,
    components,
  }).Compiler(ast) : null

export default renderAst
