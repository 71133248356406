import React, { FunctionComponent, useState } from "react"
import styles from "./menu.module.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import cx from "classnames"
import InstagramLink from "./instagram-link"
import useScrollLock from "../hooks/use-scroll-lock"
import GatsbyLink from "gatsby-link"
import useLastOnRow from "../hooks/use-last-on-row"
import Logo from "./logo"

export type MenuToggleColor = "pink" | "purple"

type Page = {
  slug: string
  isHomepage: boolean
}

export function getLink({ slug, isHomepage }: Page) {
  return isHomepage ? "/" : `/${slug}`
}

const Menu: FunctionComponent<{ toggleColor?: MenuToggleColor }> = ({
  toggleColor,
}) => {
  const { contentfulHeaderFooter: data } = useStaticQuery(graphql`
    query Menu {
      contentfulHeaderFooter {
        menu {
          label
          page {
            slug
            isHomepage
          }
        }
      }
    }
  `)

  const menuItems = data.menu || []

  const [ref, setBodyScrollLocked] = useScrollLock(null)
  const [refs, menuItemCns] = useLastOnRow<HTMLLIElement>(
    menuItems.length,
    styles.menuListItemLast
  )

  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = (open: boolean) => {
    setIsOpen(open)
    setBodyScrollLocked(open)
  }

  return (
    <>
      <header className={styles.header}>
        <div className="grid">
          <h1 className={styles.headerLogo}>
            <Link to="/">
              <Logo />
              <span className="sr-only">Yalla Rinkeby</span>
            </Link>
          </h1>
          <button
            className={cx(styles.toggle, {
              [styles.open]: isOpen,
              [styles.togglePink]: toggleColor === "pink",
            })}
            onClick={() => toggleMenu(!isOpen)}
          >
            <svg width="55" height="37" viewBox="0 0 55 37">
              <line x2="55" y1="1.5" y2="1.5" />
              <line x2="55" y1="18.5" y2="18.5" />
              <line x2="55" y1="35.5" y2="35.5" />
            </svg>
            <span className="sr-only">toggle menu</span>
          </button>
        </div>
      </header>
      <nav ref={ref} aria-hidden={!isOpen} className={styles.nav}>
        <ul className={styles.menuList}>
          {menuItems.map(
            ({ label, page }: { label: string; page: Page }, i: number) => (
              <li
                key={label + page.slug}
                ref={refs.current[i]}
                className={cx(styles.menuListItem, menuItemCns[i])}
              >
                <GatsbyLink
                  onClick={() => toggleMenu(false)}
                  className={cx(styles.menuItem)}
                  to={getLink(page)}
                >
                  {label}
                </GatsbyLink>
              </li>
            )
          )}
        </ul>
        <p className="instagram">
          <InstagramLink />
        </p>
      </nav>
    </>
  )
}

export default Menu
