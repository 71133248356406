import React, { FunctionComponent } from "react"
import styles from "./footer.module.css"
import InstagramLink from "./instagram-link"
import { Link, useStaticQuery, graphql } from "gatsby"
import renderAst from "../lib/render-ast"
import Logo from "./logo"

const Footer: FunctionComponent = () => {
  const { contentfulHeaderFooter: data } = useStaticQuery(graphql`
    query Footer {
      contentfulHeaderFooter {
        instagramUrl
        address {
          childMarkdownRemark {
            htmlAst
          }
        }
        contact {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  `)

  if (!data) {
    return null
  }
  return (
    <footer className={styles.footer}>
      <div className="grid">
        <div className={styles.logo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <p className={styles.instagram}>
          <InstagramLink />
        </p>
        <div className={styles.contact}>
          {renderAst(data.contact.childMarkdownRemark.htmlAst)}
        </div>
        <div className={styles.address}>
          {renderAst(data.address.childMarkdownRemark.htmlAst)}
        </div>
      </div>
    </footer>
  )
}

export default Footer
