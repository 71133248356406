import {
  useRef,
  useEffect,
  useState,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from "react"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

function useScrollLock<T>(
  defaultRef: T
): [MutableRefObject<T>, Dispatch<SetStateAction<boolean>>] {
  const ref = useRef<T>(defaultRef)
  const [isLocked, setBodyScrollLocked] = useState(false)

  useEffect(() => {
    if (isLocked) {
      disableBodyScroll(ref.current)
    } else {
      enableBodyScroll(ref.current)
    }

    return clearAllBodyScrollLocks
  }, [isLocked, ref.current])

  return [ref, setBodyScrollLocked]
}

export default useScrollLock
